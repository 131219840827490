import { observer } from 'mobx-react-lite';
/* import { Button } from '@blueprintjs/core'; */
import { useProject } from './project';
/* import { Cloud } from '@blueprintjs/icons'; */

  export const CloudWarning = observer(() => {
  const project = useProject();
  return null;
  return (
    <div>
      {/* <p>
        If you want to have bigger storage and enable cloud saving please sign
        in with puter.com.
      </p> */}
    </div>
  );
});
